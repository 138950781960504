<template>
  <div class="p-4">
    <pending-users></pending-users>
  </div>
</template>
<script>
import PendingUsers from './PendingUsers.vue';

export default {
  components: {
    PendingUsers,
  },
};
</script>
